<template>
	<v-tooltip v-bind="$attrs" z-index="300">
		<template v-slot:activator="{ on }">
			<v-btn :small="smallBtn" icon v-on="on" @click.prevent.stop="togglePagesThumbnails()">
				<v-icon>view_list</v-icon>
			</v-btn>
		</template>
		<span v-text="displayThumbnails ? $t('documents.preview.hide_pages_thumbnails') : $t('documents.preview.display_pages_thumbnails')" />
	</v-tooltip>
</template>

<script>
import DocumentVersionsModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentVersionsModuleGuard'
import SmallButton from "@/mixins/SmallButton";

export default {
	name: 'ShowPDFDocumentPageThumbnails',
	mixins: [
			DocumentVersionsModuleGuard,
			SmallButton,
	],
	props: {
		displayThumbnails: {
			type: Boolean,
			required: true
		}
	},
	methods: {
		togglePagesThumbnails: function () {
			this.eventBus.emit(this.events.preview.TOGGLE_THUMBNAILS)
		}
	}
}
</script>
